<template>
  <b-container fluid>
    <h4>Caixa</h4>
    <b-row>
      <b-col>
        <b-form @submit="submitForm">
          <form-input
            v-model="form.description"
            label="Descrição:"
            placeholder="Digite a descrição da caixa"
            icon="card-text"
            :errors="errors['description']"
            required
          />

          <b-row>
            <b-col>
              <form-input
                v-model="form.height"
                label="Altura (CM):"
                placeholder="Informe a altura em centimetros"
                icon="rulers"
                :errors="errors['height']"
                required
              />
            </b-col>
            <b-col>
              <form-input
                v-model="form.width"
                label="Largura (CM):"
                placeholder="Informe a largura em centimetros"
                icon="rulers"
                :errors="errors['width']"
                required
              />
            </b-col>
            <b-col>
              <form-input
                v-model="form.length"
                label="Comprimento (CM):"
                placeholder="Informe a comprimento em centimetros"
                icon="rulers"
                :errors="errors['length']"
                required
              />
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary">SALVAR</b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
export default {
  components: { FormInput },
  name: "detailsBox",
  data() {
    return {
      form: { ...this.$store.state.box.box },
    };
  },
  computed: {
    ...mapState("box", ["errors"]),
  },
  methods: {
    ...mapActions("box", ["createBox", "updateBox", "getOneBox"]),
    ...mapMutations("box", ["setErrors"]),
    ...mapMutations(["setToasted", "setOverlay"]),

    resetForm() {
      this.form = {
        id: "",
        description: "",
        height: "",
        width: "",
        length: "",
      };
    },
    async submitForm(event) {
      event.preventDefault();
      const { form } = this;

      let formatedObj = {};
      Object.keys(form).forEach((objKey) => {
        if (objKey !== "id" && objKey !== "description") {
          formatedObj[objKey] = Number(form[objKey]);
        } else {
          formatedObj[objKey] = form[objKey];
        }
      });

      if (form.id) {
        this.updateBox(formatedObj);
      } else {
        this.createBox(formatedObj);
      }
    },
  },
  async created() {
    this.setOverlay(true);
    const { id } = this.$route.params;

    if (id && !this.form.id) {
      await this.getOneBox(id);
      this.setErrors({});
      this.form = { ...this.$store.state.box.box };
    }
    this.setOverlay(false);
  },
};
</script>

<style></style>
